import React from 'react'

function ProblemText() {
    return (
        <div>
            <p>
                My wife runs an online jewellery store <a href="https://www.lexlets.co.uk" target="_blank"> lexlets.co.uk</a>. She 
                has a wide variety of beads and charms that she uses to make the jewellery and therefore keeping on track of her 
                inventory was difficult. 
            </p>
            <p>
                On numerous occasions, she had sold a product only to discover that one of the materials was out of stock. This then 
                caused a delay in her making and shipping her order.
            </p>
            <p>
                In addition to this, managing the business accounts was very time consuming as I had to manually calculate income and categorize expenses.
            </p>
            <p>
                This was at the very beginning of my coding journey and as I wanted to learn C# I decided to create a program to solve the above issues
                using this language.
            </p>
        </div>
    )
}

export default ProblemText
