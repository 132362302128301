import React from 'react'
// import './css/ProjectWelcome.css'
function ProjectWelcome() {
    return (
        <>            
            Here is a list of some of my projects. My most recent project which is the most feature rich is APOS.<br />
            Please check them out, each project has it's own video demo.
        </>
    )
}

export default ProjectWelcome
