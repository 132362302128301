import React from 'react'
import PageHeading from '../PageHeading'
import PageBodySection from '../PageBodySection'

function SudokuSolver() {
    return (
        <div>
            <PageHeading 
                title="Sudoku Solver"
                text="A program to solve Sudoku puzzles"
            />

            <PageBodySection 
               
               text="Some Stuff"
            />  
        </div>
    )
}

export default SudokuSolver
